import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { getInfo } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: {
      name: 'English',
      icon:require('../assets/images/mg.png'),
      key: 'en-us',
    },
    token: "",
    userInfo: null,
    UserInfo2: null,
    dialog:0,
    service:"",
    nationCode:"1",
    isCodeShow:false
  },
  getters: {
  },
  mutations: {
    changeLanguage(state, value) {
      state.language = value;
    },
    changeUserInfo(state, value) {
      state.userInfo = value;
      state.token = value.token;
      state.userInfo.token = undefined
    },
    changeToken(state, value) {
      state.token = value;
    },
    changeUserInfo2(state, value) {
      state.userInfo2 = value;
    },
    changeDialog(state, value){
      state.dialog = value;
    },
    changeService(state, value){
      state.service = value;
    },
    changeNationCode(state, value){
      state.nationCode = value;
    },
    changeIsCodeShow(state, value){
      state.isCodeShow = value;
    }
  },
  actions: {
    initUserInfo({ commit }) {
      new Promise((resolve, reject) => {
        getInfo().then(res => {
          if (res.code === 1) {
            commit('changeUserInfo2', res.data)
          }
          resolve(res);
        }).catch(err =>reject(err));
      })
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    key: "TRON_TRX_VUEX",
    storage: window.localStorage,
    reducer(store) {
      return {
        language:store.language,
        token:store.token,
        userInfo:store.userInfo,
        userInfo2:store.userInfo2,
        dialog:store.dialog,
        service:store.service,
        nationCode:store.nationCode,
      }
    }
  })]
})
