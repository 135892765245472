import { createAxiosByinterceptors } from "@/api/request";
// import i18n from '../lang'

const request = createAxiosByinterceptors({
    baseURL: 'https://www.okwinapi.com/api',
    timeout: 10000,
});
// 邮箱验证码
export function sendEmail(data) {
    return request({
        url: "/sendEmail",
        method: "get",
        loading: false,
        isErrShow:false,
        params: data,
    });
}
// 注册
export function Sign(data) {
    return request({
        url: "/register",
        method: "post",
        // loading: true,
        data,
    });
}

// 登录
export function Login(data) {
    return request({
        url: "/login",
        method: "post",
        // loading: false,
        data,
    });
}
// 基础信息
export function getBase(data) {
    return request({
        url: "/getBase",
        method: "post",
        // loading: false,
        data,
    });
}
// 获取用户信息
export function getInfo(data) {
    return request({
        url: "/getInfo",
        method: "post",
        loading: false,
        data,
    });
}
// 用户充值
export function recharge(data) {
    return request({
        url: "/recharge",
        method: "post",
        loading: false,
        data,
    });
}
// 用户提现
export function withdraw(data) {
    return request({
        url: "/withdraw",
        method: "post",
        loading: true,
        data,
    });
}
// 用户修改密码
export function updateUserInfo(data) {
    return request({
        url: "/updateUserInfo",
        method: "post",
        loading: true,
        data,
    });
}
// 获取团队
export function getTeam(data) {
    return request({
        url: "/getTeam",
        method: "post",
        loading: true,
        data,
    });
}
// 周薪菜单
export function teamAwardLevel(data) {
    return request({
        url: "/teamAwardLevel",
        method: "post",
        loading: true,
        data,
    });
}
// 提现记录
export function getWithdraw(data) {
    return request({
        url: "/getWithdraw",
        method: "post",
        loading: true,
        data,
    });
}
// 交易记录
export function getBalances(data) {
    return request({
        url: "/getBalances",
        method: "post",
        loading: true,
        data,
    });
}
// 套餐升级
export function upGrade(data) {
    return request({
        url: "/upGrade",
        method: "post",
        loading: true,
        data,
    });
}
// 量化产品购买
export function buyPloy(data) {
    return request({
        url: "/buyPloy",
        method: "post",
        loading: true,
        data,
    });
}
// 提现比例获取
export function getWithdrawRate(data) {
    return request({
        url: "/getWithdrawRate",
        method: "post",
        loading: true,
        data,
    });
}
// 领取周薪
export function receiveBonus(data) {
    return request({
        url: "/receiveBonus",
        method: "post",
        // loading: true,
        data,
    });
}