<template>
  <div id="app">
    <router-view />
    <van-popup v-model="$store.state.isCodeShow" round position="bottom" :style="{ height: '308px' }">
      <van-picker :confirm-button-text="$t('app.a1')" :cancel-button-text="$t('app.a2')" show-toolbar :columns="conlist" @confirm="onConfirm" @cancel="onCancel">
        <template #title>
          <van-search v-model="nationCode" @input="input" shape="round" :placeholder="$t('app.a3')" />
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      nationCode: "",
      allList: [
        "Afghanistan (‫افغانستان‬‎) +93",
        "Albania (Shqipëri) +355",
        "Algeria (‫الجزائر‬‎) +213",
        "American Samoa +1684",
        "Andorra +376",
        "Angola +244",
        "Anguilla +1264",
        "Antigua and Barbuda +1268",
        "Argentina +54",
        "Armenia (Հայաստան) +374",
        "Aruba +297",
        "Australia +61",
        "Austria (Österreich) +43",
        "Azerbaijan (Azərbaycan) +994",
        "Bahrain (‫البحرين‬‎) +973",
        "Bangladesh (বাংলাদেশ) +880",
        "Barbados +1246",
        "Belarus (Беларусь) +375",
        "Belgium (België) +32",
        "Belize +501",
        "Benin (Bénin) +229",
        "Bermuda +1441",
        "Bhutan (འབྲུག) +975",
        "Bolivia +591",
        "Bosnia and Herzegovina (Босна и Херцеговина) +387",
        "Botswana +267",
        "Brazil (Brasil) +55",
        "British Indian Ocean Territory +246",
        "Brunei Darussalam +673",
        "Bulgaria (България) +359",
        "Burkina Faso +226",
        "Burundi (Uburundi) +257",
        "Cambodia (កម្ពុជា) +855",
        "Cameroon (Cameroun) +237",
        "Canada +1",
        "Cape Verde (Kabu Verdi) +238",
        "Cayman Islands +1345",
        "Central African Republic (République centrafricaine) +236",
        "Chad (Tchad) +235",
        "Chile +56",
        "China (中国) +86",
        "Colombia +57",
        "Comoros (‫جزر القمر‬‎) +269",
        "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo) +243",
        "Congo (Republic) (CongoBrazzaville) +242",
        "Cook Islands +682",
        "Costa Rica +506",
        "Côte d’Ivoire +225",
        "Croatia (Hrvatska) +385",
        "Cuba +53",
        "Curaçao +599",
        "Cyprus (Κύπρος) +357",
        "Czech Republic (Česká republika) +420",
        "Denmark (Danmark) +45",
        "Djibouti +253",
        "Dominica +1767",
        "Dominican Republic (República Dominicana) +1",
        "Ecuador +593",
        "Egypt (‫مصر‬‎) +20",
        "El Salvador +503",
        "Equatorial Guinea (Guinea Ecuatorial) +240",
        "Eritrea +291",
        "Estonia (Eesti) +372",
        "Ethiopia +251",
        "Falkland Islands (Islas Malvinas) +500",
        "Faroe Islands (Føroyar) +298",
        "Fiji +679",
        "Finland (Suomi) +358",
        "France +33",
        "French Guiana (Guyane française) +594",
        "French Polynesia (Polynésie française) +689",
        "Gabon +241",
        "Georgia (საქართველო) +995",
        "Germany (Deutschland) +49",
        "Ghana (Gaana) +233",
        "Gibraltar +350",
        "Greece (Ελλάδα) +30",
        "Greenland (Kalaallit Nunaat) +299",
        "Grenada +1473",
        "Guadeloupe +590",
        "Guam +1671",
        "Guatemala +502",
        "Guernsey +1481",
        "Guinea (Guinée) +224",
        "Guinea Bissau (Guiné Bissau) +245",
        "Guyana +592",
        "Haiti +509",
        "Honduras +504",
        "Hong Kong (香港) +852",
        "Hungary (Magyarország) +36",
        "Iceland (Ísland) +354",
        "India (भारत) +91",
        "Indonesia +62",
        "Iran (‫ایران‬‎) +98",
        "Iraq (‫العراق‬‎) +964",
        "Ireland +353",
        "Israel (‫ישראל‬‎) +972",
        "Italy (Italia) +39",
        "Jamaica +1876",
        "Japan (にほんこく) +81",
        "Jordan (‫الأردن‬‎) +962",
        "Kazakhstan (Казахстан) +7",
        "Kenya +254",
        "Kiribati +686",
        "Kosovo +383",
        "Kuwait (‫الكويت‬‎) +965",
        "Kyrgyzstan (Кыргызстан) +996",
        "Laos (ລາວ) +856",
        "Latvia (Latvija) +371",
        "Lebanon (‫لبنان‬‎) +961",
        "Lesotho +266",
        "Liberia +231",
        "Libya (‫ليبيا‬‎) +218",
        "Liechtenstein +423",
        "Lithuania (Lietuva) +370",
        "Luxembourg +352",
        "Macao (澳門) +853",
        "Macedonia (FYROM) (Македонија) +389",
        "Madagascar (Madagasikara) +261",
        "Malawi +265",
        "Malaysia +60",
        "Maldives +960",
        "Mali +223",
        "Malta +356",
        "Marshall Islands +692",
        "Martinique +596",
        "Mauritania (‫موريتانيا‬‎) +222",
        "Mauritius (Moris) +230",
        "Mayotte +262",
        "Mexico (México) +52",
        "Micronesia +691",
        "Moldova (Republica Moldova) +373",
        "Monaco +377",
        "Mongolia (Монгол) +976",
        "Montenegro (Crna Gora) +382",
        "Montserrat +1664",
        "Morocco (‫المغرب‬‎) +212",
        "Mozambique (Moçambique) +258",
        "Myanmar (Burma) (မြန်မာ) +95",
        "Namibia (Namibië) +264",
        "Nauru +674",
        "Nepal (नेपाल) +977",
        "Netherlands (Nederland) +31",
        "Calédonie) +687",
        "New Zealand +64",
        "Nicaragua +505",
        "Niger (Nijar) +227",
        "Nigeria +234",
        "Niue +683",
        "Norfolk Island +672",
        "North Korea (조선민주주의인민공화국) +850",
        "Northern Mariana Islands +1670",
        "Norway (Norge) +47",
        "Oman (‫عُمان‬‎) +968",
        "Pakistan (‫پاکستان‬‎) +92",
        "Palau +680",
        "Palestine (‫فلسطين‬‎) +970",
        "Panama (Panamá) +507",
        "Papua New Guinea +675",
        "Paraguay +595",
        "Peru (Perú) +51",
        "Philippines +63",
        "Poland (Polska) +48",
        "Portugal +351",
        "Puerto Rico +1",
        "Qatar (‫قطر‬‎) +974",
        "Réunion (La Réunion) +262",
        "Romania (România) +40",
        "Russia (Россия) +7",
        "Rwanda +250",
        "Saint Helena +290",
        "Saint Kitts and Nevis +1869",
        "Saint Lucia +1758",
        "Martin) +590",
        "Saint Pierre and Miquelon (Saint-Pierre-etMiquelon) +508",
        "Saint Vincent and the Grenadines +1784",
        "Samoa +685",
        "San Marino +378",
        "São Tomé and Príncipe (São Tomé e Príncipe) +239",
        "Saudi Arabia (‫المملكة العربية السعودية‬‎) +966",
        "Senegal (Sénégal) +221",
        "Serbia (Србија) +381",
        "Seychelles +248",
        "Sierra Leone +232",
        "Singapore +65",
        "Sint Maarten +1721",
        "Slovakia (Slovensko) +421",
        "Slovenia (Slovenija) +386",
        "Solomon Islands +677",
        "Somalia (Soomaaliya) +252",
        "South Africa +27",
        "South Korea (대한민국) +82",
        "South Sudan (‫جنوب السودان‬‎) +211",
        "Spain (España) +34",
        "Sri Lanka (ශ්‍රී ලංකාව) +94",
        "Sudan (‫السودان‬‎) +249",
        "Suriname +597",
        "Swaziland +268",
        "Sweden (Sverige) +46",
        "Switzerland (Schweiz) +41",
        "Syria (‫سوريا‬‎) +963",
        "Taiwan (台灣) +886",
        "Tajikistan +992",
        "Tanzania +255",
        "Thailand (ไทย) +66",
        "The Bahamas +1242",
        "The Gambia +220",
        "Leste +670",
        "Togo +228",
        "Tokelau +690",
        "Tonga +676",
        "Trinidad and Tobago +1868",
        "Tunisia (‫تونس‬‎) +216",
        "Turkey (Türkiye) +90",
        "Turkmenistan +993",
        "Turks and Caicos Islands +1649",
        "Tuvalu +688",
        "United States +1",
        "United Kingdom +44",
        "Uganda +256",
        "Ukraine (Україна) +380",
        "United Arab Emirates (‫الإمارات العربية المتحدة‬‎) +971",
        "Uruguay +598",
        "Uzbekistan (Oʻzbekiston) +998",
        "Vanuatu +678",
        "Vatican City (Città del Vaticano) +379",
        "Venezuela +58",
        "Vietnam (Việt Nam) +84",
        "Wallis and Futuna +681",
        "Western Sahara (‫الصحراء الغربية‬‎) +212",
        "Yemen (‫اليمن‬‎) +967",
        "Zambia +260",
        "Zimbabwe +263"
      ],
      conlist: [],
      isfloat: false
    }
  },
  created() {
    this.$i18n.locale = this.$store.state.language.key
    this.conlist = this.allList;
  },
  methods: {
    onConfirm(value, index) {
      // console.log(`当前值：${value}, 当前索引：${index}`);
      const e = value.split("+")
        , i = e[e.length - 1];
      this.$store.commit('changeNationCode',i)
      this.onCancel()
      this.nationCode = ""
      this.conlist = this.allList
    },
    onCancel() {
      // console.log('取消');
      this.$store.commit('changeIsCodeShow',false)
      this.nationCode = ""
      this.conlist = this.allList
    },
    input(e) {
      if (e != '') {
        this.conlist = this.allList.filter(item => item.includes(e))
      } else {
        this.conlist = this.allList
      }
    }
  }
}
</script>
<style lang="scss">
// 声明字体
@font-face {
  font-family: 'bPlex-Regular';
  src: url('./assets/fonts/bPlex-Regular.woff') format('woff');
}

body {
  font-size: 16px !important;
  color: #54111a;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-wrap: break-word;
  font-family: 'bPlex-Regular';
}

#app {
  max-width: 420px;
  background: #fff;
  margin: 0 auto;
  min-height: 100vh;
}
</style>
