<template>
  <div class="home">
    <van-notice-bar left-icon="volume-o" color="#fff" background="#c3322a">
      {{ noticeTop }}
    </van-notice-bar>
    <div class="top-box">
      <div class="top-cont">
        <img class="lfimg" src="../assets/images/logo-white.png" alt="">
        <div class="top-cont-r" @click="$router.push('/lang')">
          <img :src="$store.state.language.icon" alt="">
          {{ $store.state.language.name }}
        </div>
      </div>
      <div class="head"> {{ $t('home.a1', {
        num1: (tronsocket.newAccount_number /
          1000000).toFixed(4), num2: (tronsocket.newTransaction_number / 1000000000).toFixed(4)
      }) }}</div>
    </div>
    <div class="data-content">
      <div class="data-content-item">
        <div class="data-content-item-val">
          <countTo :startVal='tronsocket.account_number' :endVal='tronsocket.newAccount_number' :duration='2000'>
          </countTo>
        </div>
        <div class="data-content-item-name">{{ $t('home.a2') }}</div>
      </div>
      <div class="data-content-item">
        <div class="data-content-item-val">
          <countTo :startVal='tronsocket.transaction_number' :endVal='tronsocket.newTransaction_number'
            :duration='2000'></countTo>
        </div>
        <div class="data-content-item-name">{{ $t('home.a3') }}</div>
      </div>
      <div class="data-content-item">
        <div class="data-content-item-val">
          <countTo :startVal='tronsocket.block_number' :endVal='tronsocket.newBlock_number' :duration='2000'></countTo>
        </div>
        <div class="data-content-item-name">{{ $t('home.a4') }}</div>
      </div>
      <div class="data-content-item">
        <div class="data-content-item-val">$ {{ (tronsocket.vip_core_token_amount_his_total / 1000000000000).toFixed(3)
          }} B
        </div>
        <div class="data-content-item-name">{{ $t('home.a5') }}</div>
      </div>
    </div>
    <div class="content-box">
      <div class="menu my-card">
        <div class="menu-item" @click="$router.push('/invite')">
          <img src="../assets/images/menu1.png" alt="">
          <div class="name">{{ $t('home.a6') }}</div>
        </div>
        <div class="menu-item" @click="$router.push('/recharge')">
          <img src="../assets/images/menu2.png" alt="">
          <div class="name">{{ $t('home.a7') }}</div>
        </div>
        <div class="menu-item" @click="$router.push('/explain')">
          <img src="../assets/images/menu3.png" alt="">
          <div class="name">{{ $t('home.a8') }}</div>
        </div>
        <a class="menu-item" :href="service">
          <img src="../assets/images/menu4.png" alt="">
          <div class="name">{{ $t('home.a9') }}</div>
        </a>
      </div>
      <div class="my-title my-card">
        {{ $t('home.a10') }}
      </div>
      <div class="my-card item" v-for="(v, i) in goods1" :key="i + '1'">
        <div class="item-title">{{ $t('home.a10') }}</div>
        <div class="item-cont">
          <div>{{ $t('home.a12') }}</div>
          <div>{{ v.n_price }}USDT</div>
        </div>
        <div class="item-cont">
          <div>{{ $t('home.a13') }}</div>
          <div>{{ v.e_price }} USDT</div>
        </div>
        <div class="btn3" v-if="v.n_price == 5 && $store.state.userInfo2.is_buy != 1" @click="upgradeClick(v)">
          {{ $t('home.a22') }} </div>
        <div class="btn4" v-else-if="$store.state.userInfo2.is_buy"> {{ getBtnTxt(v) }} </div>
        <div class="btn3" v-else @click="goRouter(v)"> {{ getBtnTxt(v) }} </div>
      </div>
      <div class="my-title my-card">
        {{ $t('home.a11') }}
      </div>
      <div class="my-card item" v-for="(v, i) in goods2" :key="i + '2'">
        <div class="item-title">{{ $t('home.a11') }}</div>
        <div class="item-cont">
          <div>{{ $t('home.a12') }}</div>
          <div>{{ v.n_price }}USDT</div>
        </div>
        <div class="item-cont">
          <div>{{ $t('home.a13') }}</div>
          <div>{{ v.e_price }} USDT</div>
        </div>
        <div class="btn4" v-if="$store.state.userInfo2.is_buy"> {{ getBtnTxt(v) }} </div>
        <div class="btn3" v-else @click="goRouter(v)"> {{ getBtnTxt(v) }} </div>
      </div>
      <div class="my-title my-card">
        {{ $t('home.a14') }}
      </div>
      <div class="my-card item" v-for="(v, i) in goods3" :key="i + '3'">
        <div class="item-title">{{ $t('home.a14') }}</div>
        <div class="item-cont">
          <div>{{ $t('home.a12') }}</div>
          <div>{{ v.n_price }}USDT</div>
        </div>
        <div class="item-cont">
          <div>{{ $t('home.a13') }}</div>
          <div>{{ v.e_price }} USDT</div>
        </div>
        <div class="btn4" v-if="$store.state.userInfo2.is_buy"> {{ getBtnTxt(v) }} </div>
        <div class="btn3" v-else @click="goRouter(v)"> {{ getBtnTxt(v) }} </div>
      </div>
      <div class="bottom">
        <img src="../assets/images/logo.png" alt="">
      </div>
    </div>
    <!-- 弹窗 -->
    <van-overlay :show="show">
      <div class="prompt" @click.stop>
        <div class="prompt-title"> {{ $t('home.a15') }} </div>
        <div class="prompt-text">{{ $t('home.a16', { num1: item.n_price }) }}</div>
        <div class="prompt-btn">
          <div class="btn2" @click="show = false">{{ $t('home.a17') }}</div>
          <div class="btn1" @click="buyPloy(item)">{{ $t('home.a18') }}</div>
        </div>
      </div>
    </van-overlay>
    <!-- tabbar -->
    <TabBar />
  </div>
</template>

<script>
import WebSocketClient from '@/utils/WebSocketClient'
import { getBase, buyPloy } from "@/api"
import TabBar from "@/components/TabBar.vue";
import countTo from 'vue-count-to';
export default {
  name: 'Home',
  components: {
    TabBar,
    countTo
  },
  data() {
    return {
      wsClient: null,
      notice: "",
      noticeTop: "",
      service: "",
      goods1: null,
      goods2: null,
      goods3: null,
      ploy: 1,
      tronsocket: {
        account_number: 0,
        newAccount_number: 0,
        block_number: 0,
        newBlock_number: 0,
        transaction_number: 0,
        newTransaction_number: 0,
        vip_core_token_amount_his_total: 0
      },
      show: false,
      item: {}
    }
  },
  created() {
    this.messageWebSocket()
    this.getList()
  },
  methods: {
    getList() {
      getBase().then(res => {
        if (res.code == 1) {
          this.notice = res.data.notice
          this.noticeTop = res.data.top
          this.service = res.data.service
          this.$store.commit('changeService', res.data.service)
          this.goods1 = res.data.goods.filter(v => v.ploy_id == 1)
          this.goods2 = res.data.goods.filter(v => v.ploy_id == 2)
          this.goods3 = res.data.goods.filter(v => v.ploy_id == 3)
          this.ploy = res.data.ploy


          let timestamp = Math.floor(new Date().getTime() / 1000)
          // 如果相差一天
          if (this.$store.state.dialog + 24 * 60 * 60 <= timestamp) {
            this.$Dialog.alert({
              title: this.$t('home.a19'),
              message: res.data.notice,
              theme: 'round-button',
              confirmButtonText: this.$t('home.a18'),
              className: "DialogClassName"
            }).then(() => {
              // on close
              // 获取时间戳
              // 获取首页数据
              this.$store.commit('changeDialog', timestamp)
            });
          }
        }
      })
    },
    messageWebSocket() {
      // 创建 WebSocketClient 实例
      this.wsClient = new WebSocketClient('wss://apilist.tronscanapi.com/api/tronsocket');
      // 添加事件监听器
      this.wsClient.on('open', () => console.log('已连接到 WebSocket 服务器。'));
      this.wsClient.on('message', (data) => {
        let res = JSON.parse(data)
        this.tronsocket.account_number = this.tronsocket.newAccount_number
        this.tronsocket.newAccount_number = res.account_number
        this.tronsocket.block_number = this.tronsocket.newBlock_number
        this.tronsocket.newBlock_number = res.block_number
        this.tronsocket.transaction_number = this.tronsocket.newTransaction_number
        this.tronsocket.newTransaction_number = res.transaction_number
        this.tronsocket.vip_core_token_amount_his_total = res.vip_core_token_amount_his_total
      });
      this.wsClient.on('close', (event) => {
        console.log('WebSocket 已关闭:', event.reason)
      });
      this.wsClient.on('error', (error) => console.error('WebSocket 错误:', error));
      // 连接到 WebSocket 服务器
      this.wsClient.connect();
      // 发送消息
      // this.wsClient.send('WebSocket！');
    },
    goRouter(e) {
      if (e.n_price > this.$store.state.userInfo2.balances) {
        this.$router.push('/recharge')
      } else {
        if (this.ploy < e.ploy_id) {
          this.$router.push('/level')
        } else {
          this.upgradeClick(e)
        }
      }
    },
    getBtnTxt(e) {
      if (e.n_price > this.$store.state.userInfo2.balances) {
        return this.$t('home.a20')
      } else {
        if (this.ploy < e.ploy_id) {
          return this.$t('home.a21')
        } else {
          return this.$t('home.a22')
        }
      }
    },
    upgradeClick(v) {
      this.item = v
      this.show = true
    },
    buyPloy(e) {
      buyPloy({ goods_id: e.id }).then(res => {
        if (res.code == 1) {
          this.$toast.success(this.$t('home.a23'))
          this.show = false
          this.getList()
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .DialogClassName {
  .van-button {
    border-radius: 2px !important;
  }
}

.home {
  .my-card {
    background-color: #f6f4ff;
    border: 2px solid #d7588d;
    border-radius: 15px;
    padding: 10px;
  }

  .top-box {
    padding: 10px;
    box-sizing: border-box;
    background: url('@/assets/images/head-bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    height: 190px;

    .top-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .lfimg {
        width: 130px;
      }

      .top-cont-r {
        background-color: #c3322a;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        align-items: center;
        display: flex;
        gap: 5px;
        font-size: 16px;
        margin: 10px;

        img {
          width: 20px;
        }
      }
    }

    .head {
      background-color: #c3322a;
      bottom: 4px;
      box-sizing: border-box;
      color: #fff;
      font-size: 12px;
      left: 5%;
      padding: 5px 10px;
      position: absolute;
      text-align: center;
      width: 90%;
    }
  }

  .data-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .data-content-item {
      font-size: 12px;
      text-align: center;
      border-right: 1px solid hsla(0, 0%, 50%, .3);
      padding: 0 5px;
      box-sizing: border-box;
      color: #54111a;

      .data-content-item-val {
        margin: 5px 0;
      }
    }
  }

  .content-box {
    padding: 0 10px;
    box-sizing: border-box;

    .menu {
      display: flex;
      justify-content: space-between;

      .menu-item {
        width: 30%;
        text-align: center;

        img {
          width: 50px;
          height: 50px;
        }

        .name {
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }

    .my-title {
      margin: 10px 0;
      background: #f7f6fd;
    }

    .item {
      margin: 10px 0;

      .item-title {
        margin: 20px 0;
        font-weight: 700;
        text-align: center;
      }

      .item-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
      }

      .btn3 {
        background-color: #e6665f;
        text-align: center;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 10px;
        color: #fff;
        width: 55%;
        margin: 10px auto;
      }

      .btn4 {
        background-color: #dddddd;
        text-align: center;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 10px;
        color: #54111a;
        width: 55%;
        margin: 10px auto;
      }
    }

    .bottom {
      margin-top: 70px;
      text-align: center;

      img {
        width: 60%;
      }
    }
  }


}

.prompt {
  background-color: #f6f4ff;
  border: 2px solid #d7588d;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);

  .prompt-title {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  .prompt-text {
    margin: 10px 0;
    text-align: center;
  }

  .prompt-btn {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn1,
  .btn2 {
    width: 45%;
    box-sizing: border-box;
    padding: 10px 0;
    border-radius: 10px;
    text-align: center;
    font-weight: 500;
  }

  .btn2 {
    background-color: #ddd;
  }

  .btn1 {
    background-color: #c3322a;
    color: #fff;
  }
}
</style>
