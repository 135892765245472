import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import $store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  }
  ,
  {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "level" */ '../views/mine.vue')
  },
  {
    path: '/level',
    name: 'level',
    component: () => import(/* webpackChunkName: "level" */ '../views/level.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register.vue')
  },
  {
    path: '/lang',
    name: 'lang',
    component: () => import(/* webpackChunkName: "lang" */ '../views/lang.vue')
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import(/* webpackChunkName: "invite" */ '../views/invite.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import(/* webpackChunkName: "recharge" */ '../views/recharge.vue')
  },
  {
    path: '/explain',
    name: 'explain',
    component: () => import(/* webpackChunkName: "explain" */ '../views/explain.vue')
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import(/* webpackChunkName: "withdrawal" */ '../views/withdrawal.vue')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import(/* webpackChunkName: "changePassword" */ '../views/changePassword.vue')
  },
  {
    path: '/teamList',
    name: 'teamList',
    component: () => import(/* webpackChunkName: "teamList" */ '../views/teamList.vue')
  },
  {
    path: '/leaderAwardLevel',
    name: 'leaderAwardLevel',
    component: () => import(/* webpackChunkName: "leaderAwardLevel" */ '../views/leaderAwardLevel.vue')
  },
  {
    path: '/withdrawalsRecord',
    name: 'withdrawalsRecord',
    component: () => import(/* webpackChunkName: "withdrawalsRecord" */ '../views/withdrawalsRecord.vue')
  },
  {
    path: '/transactionRecord',
    name: 'transactionRecord',
    component: () => import(/* webpackChunkName: "transactionRecord" */ '../views/transactionRecord.vue')
  },
  {
    path: '/referralRewardRecord',
    name: 'referralRewardRecord',
    component: () => import(/* webpackChunkName: "referralRewardRecord" */ '../views/referralRewardRecord.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 路由拦截器
router.beforeEach((to, from, next) => {
  if (to.path != '/login' && !$store.state.token&&to.path != '/lang'&&to.path != '/register') {
    next('/login')
    $store.commit('changeToken', '')
  } else {
    next()
  }
})

export default router
