<template>
  <van-tabbar v-model="active" :placeholder="true" z-index="100" :route="true" active-color="#c63127" inactive-color="#a2a6a5">
    <van-tabbar-item :to="v.path" :replace="true" v-for="v in tabs" :key="v.name">
      <span class="tabname">{{ v.name }}</span>
      <template #icon="props">
        <img class="tabicon" :src="props.active ? v.active : v.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'TabBar',
  props: {
  },
  data() {
    return {
      active: 0,
      tabs: [{
        name: this.$t('tabbar.a1'),
        active: require('@/assets/images/tabbar1-active.png'),
        inactive: require('@/assets/images/tabbar1.png'),
        path:'/'
      }, {
        name: this.$t('tabbar.a2'),
        active: require('@/assets/images/tabbar2-active.png'),
        inactive: require('@/assets/images/tabbar2.png'),
        path:'/about'
      }, {
        name: this.$t('tabbar.a3'),
        active: require('@/assets/images/tabbar3-active.png'),
        inactive: require('@/assets/images/tabbar3.png'),
        path:'/level'
      }, {
        name: this.$t('tabbar.a4'),
        active: require('@/assets/images/tabbar4-active.png'),
        inactive: require('@/assets/images/tabbar4.png'),
        path:'/mine'
      }]
    }
  }
}
</script>
<style scoped lang="scss">
.tabname{
  font-size: 10px;
}
.tabicon{
  width: 24px;
  height: 24px;
}
</style>
