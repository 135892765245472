import Vue from 'vue';
import { Button,Dialog,Icon,Toast,Lazyload,Tabbar, TabbarItem,NoticeBar,NavBar,Field,Tab, Tabs,Swipe, SwipeItem ,Overlay,Popup,Picker,Search ,List } from 'vant';

Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NoticeBar);
Vue.use(NavBar);
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Overlay );
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Search );
Vue.use(List);


Vue.prototype.$Toast = Toast
Vue.prototype.$Dialog = Dialog


// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});
