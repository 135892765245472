import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'

import 'amfe-flexible'
import './utils/vant'
import 'vant/lib/index.css';
import {initRootFontSize} from './utils/initRootFontSize'
initRootFontSize()

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
